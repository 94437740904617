// Shadow on card 
.has-shadow {
    &.bg-lighter {
        .card, .sp-pdl-item, .invoice-wrap, .accordion {
            box-shadow: 0 0 8px -3px rgba($shadow-color-light, 0.15);
        }
    }
    .card, .sp-pdl-item, .invoice-wrap, .accordion {
        box-shadow: 0 0 6px -2px rgba($shadow-color-light, 0.2);
    }
    .data-head, .data-item {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .card .data-head:not(.is-plain) {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .nav-tabs {
        box-shadow: 0 2px 0 -1px rgba($shadow-color-light, 0.06);
    }
    .nk-header {
        box-shadow: 0 3px 0 -1px rgba($shadow-color-light, 0.04);
    }
}

.nio-version {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.02em;
    position: absolute;
    right: -1px;
    bottom: -3px;
}

.code {
    &-tag {
        color: $purple;
    }
    &-class {
        color: $pink;
    }
    &-fnc {
        color: $cyan;
    }
}

.nk-switch-icon{
    color: lighten($base-light, 5%);
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
        font-size: 1.5rem;
    }
    .icon-sm{
        font-size: 1.25rem;
    }
    &:hover{
        color: $base-text;
    }
    &.active{
        color: $accent-color;
    }
}

//Dark mode switch
.dark-switch{
    position: relative;
    &:before,&:after{
        position: absolute;
        content: "";
        transform: translateY(-50%);
        border-radius: 1.5rem;
        display: block;
        top: 50%;
    }
    &:before{
        right: 0;
        width: 3rem;
        height: 1.5rem;
        background-color: $custom-control-indicator-bg;
        border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    }
    &:after{
        height: 1rem;
        width: 1rem;
        right: calc(2rem - #{$custom-control-indicator-border-width} - .125rem);
        background-color: $custom-control-indicator-border-color;
    }
    &.active{
        &:before{
            background-color: $accent-color;
            border-color: $accent-color;
        }
        &:after{
            right: calc(#{$custom-control-indicator-border-width} + .125rem);
            background-color: $white;
        }
    }
}

.is-scrollable {
    overflow: auto;
}


// country region
.country{
    &-list{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 100%;
        }
    }
    &-item{
        display: flex;
        align-items: center;;
    }
    &-flag{
        width: 1.25rem;
        margin-right: .75rem;
    }
    &-name{
        font-size: 1rem;
        color: $base-text;
    }
}

@include media-breakpoint-up(sm){
    .country{
        &-list{
            li{
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-up(lg){
    .country{
        &-list{
            li{
                width: 33.33%;
            }
        }
    }
}
//
.switch-text{
    &-normal{
        .collapsed &{
            display: none;
        }
    }
    &-collapsed{
        display: none;
        .collapsed &{
            display: inline-flex;
        }
    }
}
//
.close {
    float: right;
    font-size: $close-font-size;
    font-weight: $close-font-weight;
    line-height: 1;
    color: $close-color;
    text-shadow: $close-text-shadow;
    opacity: .5;
    &:hover{
        color: $close-color;
        text-decoration: none;
    }
    &:not(:disabled):not(.disabled) {
        &:hover-focus{
            opacity: .75;
        }
    }
  }

//alert close
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
// popover
.popover-header{
    border-bottom: $popover-border-width solid darken($popover-header-bg, 5%);
}