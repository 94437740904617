.author {
    &-list {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        li {
        	padding: 0 0.25rem;
        	&:first-child {
                margin-left: 0.75rem;
            }
        }
        &.is-grouped {
	        li {
	        	padding: 0;
	            &:not(:last-child) {
                    margin-left: -1rem;
	            }
	            &:first-child {
                    margin-left: 0.75rem;
	            }
	        }
        	.user-avatar {
                border: 2px solid $white;
            }
        }
    }
}
